//
// Copyright IBM Corp. 2016, 2023
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@use '../../config' as *;

@keyframes #{prefix}--stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
