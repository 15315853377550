//
// Copyright IBM Corp. 2018, 2023
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//
@use 'sass:meta';
@use 'styles' as *;
@use 'prefix' as *;

/// Include default type styles
/// @access public
/// @group @carbon/type
@mixin default-type {
  h1 {
    @include type-style('heading-06');
  }

  h2 {
    @include type-style('heading-05');
  }

  h3 {
    @include type-style('heading-04');
  }

  h4 {
    @include type-style('heading-03');
  }

  h5 {
    @include type-style('heading-02');
  }

  h6 {
    @include type-style('heading-01');
  }

  p {
    @include type-style('body-02');
  }

  a {
    color: var(--#{$prefix}-link-primary, #0062fe);
  }

  em {
    font-style: italic;
  }
}
