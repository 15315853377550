//
// Copyright IBM Corp. 2016, 2023
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@use '../../spacing' as *;

/// @type Number
/// @access public
/// @group button
$button-font-weight: 400 !default;

/// @type Number
/// @access public
/// @group button
$button-font-size: 0.875rem !default;

/// @type Number
/// @access public
/// @group button
$button-border-radius: 0 !default;

/// @type Number
/// @access public
/// @group button
$button-height: 3rem !default;

/// @type Value
/// @access public
/// @group button
$button-padding: calc(0.875rem - 3px) calc(#{$spacing-10} - 1px)
  calc(0.875rem - 3px) calc(#{$spacing-05} - 1px) !default;

/// @type Value
/// @access public
/// @group button
$button-padding-field: calc(0.675rem - 3px) calc(#{$spacing-10} - 4px)
  calc(0.675rem - 3px) calc(#{$spacing-05} - 4px) !default;

/// @type Value
/// @access public
/// @group button
$button-padding-sm: calc(0.375rem - 3px) calc(#{$spacing-10} - 4px)
  calc(0.375rem - 3px) calc(#{$spacing-05} - 4px) !default;

/// @type Number
/// @access public
/// @group button
$button-padding-lg: $spacing-04 !default;

/// @type Number
/// @access public
/// @group button
/// Uses the same padding-y as normal buttons, but removes extra padding-right
$button-padding-ghost: calc(0.875rem - 3px) $spacing-05 !default;

/// @type Number
/// @access public
/// @group button
/// Uses the same padding-y as field buttons, but removes extra padding-right
$button-padding-ghost-field: calc(0.675rem - 3px) $spacing-05 !default;

/// @type Number
/// @access public
/// @group button
/// Uses the same padding-y as small buttons, but removes extra padding-right
$button-padding-ghost-sm: calc(0.375rem - 3px) $spacing-05 !default;

/// @type Number
/// @access public
/// @group button
$button-border-width: 2px !default;

/// @type Number
/// @access public
/// @group button
$button-outline-width: 1px !default;
