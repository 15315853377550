// Code generated by @carbon/layout. DO NOT EDIT.
//
// Copyright IBM Corp. 2018, 2023
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

/// @type Number
/// @access public
/// @group @carbon/layout
$spacing-01: 0.125rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$spacing-02: 0.25rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$spacing-03: 0.5rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$spacing-04: 0.75rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$spacing-05: 1rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$spacing-06: 1.5rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$spacing-07: 2rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$spacing-08: 2.5rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$spacing-09: 3rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$spacing-10: 4rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$spacing-11: 5rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$spacing-12: 6rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$spacing-13: 10rem !default;

/// @type Map
/// @access public
/// @group @carbon/layout
$spacing: (
  spacing-01: $spacing-01,
  spacing-02: $spacing-02,
  spacing-03: $spacing-03,
  spacing-04: $spacing-04,
  spacing-05: $spacing-05,
  spacing-06: $spacing-06,
  spacing-07: $spacing-07,
  spacing-08: $spacing-08,
  spacing-09: $spacing-09,
  spacing-10: $spacing-10,
  spacing-11: $spacing-11,
  spacing-12: $spacing-12,
  spacing-13: $spacing-13,
);
