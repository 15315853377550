//
// Copyright IBM Corp. 2016, 2023
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@use '../functions' as *;
@use '../../../config' as *;
@use '../../../layer' as *;
@use '../../../motion' as *;
@use '../../../theme' as *;
@use '../../../utilities/z-index' as *;

/// UI shell side nav
/// @access private
/// @group ui-shell
@mixin header-panel {
  //----------------------------------------------------------------------------
  // Header Panel
  //----------------------------------------------------------------------------
  .#{$prefix}--header-panel {
    position: fixed;
    z-index: z('header');
    overflow: hidden;
    border: none;
    background-color: $layer;
    color: $text-secondary;
    inline-size: 0;
    inset-block: mini-units(6) 0;
    inset-inline-end: 0;
    transition: width $duration-fast-02 motion(exit, productive);
    will-change: width;
  }

  .#{$prefix}--header-panel--expanded {
    border-inline-end: 1px solid $border-subtle;
    border-inline-start: 1px solid $border-subtle;
    inline-size: mini-units(32);
    overflow-y: auto;
  }
}
