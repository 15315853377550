//
// Copyright IBM Corp. 2016, 2023
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//
@use '../../config' as *;

/// @access private
/// @group tag
@mixin tag-theme(
  $bg-color,
  $text-color,
  $filter-hover-color: $bg-color,
  $border-color: $bg-color
) {
  background-color: $bg-color;
  color: $text-color;

  &.#{$prefix}--tag--operational {
    border: 1px solid $border-color;

    &:hover {
      background-color: $filter-hover-color;
    }
  }

  .#{$prefix}--tag__close-icon {
    &:hover {
      background-color: $filter-hover-color;
    }
  }

  .#{$prefix}--definition-term {
    .#{$prefix}--tag__label {
      color: $text-color;
    }
  }
}
