/* .mat-progress-spinner circle, .mat-spinner circle {
  stroke: var(--color-primary);
}

.mat-form-field-underline .mat-form-field-ripple {
  background-color: var(--color-primary) !important;
}

.ng-invalid.ng-touched .mat-form-field-underline .mat-form-field-ripple {
  background-color: var(--color-error) !important;
} */

/* Change color on focus */
/* .mat-focused .mat-form-field-label {
  color: var(--color-primary) !important;
}

.mat-form-field-label {
  color: rgba(var(--color-text-rgb), .7) !important;
}

.mat-form-field-underline {
  background-color: rgba(var(--color-text-rgb), .7) !important;
}

.mat-input-element {
  caret-color: var(--color-primary) !important;
} */

/* Change underline color on focus */
/* .mat-focused .mat-form-field-ripple {
  background-color: var(--color-primary) !important;
}

.cdk-overlay-container {
  z-index: 9999 !important;
} */

/* Dialog modifications */

/* .mat-dialog-container {
  padding: 0 !important;

  overflow: hidden !important;

  border-radius: 8px !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--color-primary);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--color-primary);
} */

/* Animate items as they're being sorted. */
/* .cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
} */

/* Animate an item that has been dropped. */
/* .cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-slide-toggle-content {
  font-family: inherit !important;
}

.mat-checkbox-checked .mat-checkbox-background {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary)!important;
}

.mat-checkbox-checked .mat-checkbox-checkmark {
  color: var(--color-primary) !important;
}

.mat-checkbox-ripple .mat-ripple-element {
  background-color: var(--color-primary) !important;
} */

.cdk-overlay-container {
  z-index: 9999 !important;
}