//
// Copyright IBM Corp. 2018, 2023
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@forward '@carbon/motion';

/// Used primarily for removing elements from the screen
/// @type Function
/// @access public
/// @group motion
$ease-in: cubic-bezier(0.25, 0, 1, 1);

/// Used for adding elements to the screen or changing on-screen states at a user's input
/// @type Function
/// @access public
/// @group motion
$ease-out: cubic-bezier(0, 0, 0.25, 1);

/// Used for the majority of animations
/// @type Function
/// @access public
/// @group motion
$standard-easing: cubic-bezier(0.5, 0, 0.1, 1);

/// Base transition duration
/// @type Number
/// @access public
/// @group motion
$transition-base: 250ms;

/// Expansion duration
/// @type Number
/// @access public
/// @group motion
$transition-expansion: 300ms;
