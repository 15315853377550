// Tabs

@use '../../theme' as *;

/// @type Value
/// @access public
/// @group tabs
$tab-underline-color: 2px solid $border-subtle !default;

/// @type Value
/// @access public
/// @group tabs
$tab-underline-color-hover: 2px solid $border-strong !default;

/// @type Color
/// @access public
/// @group tabs
$tab-text-disabled: $text-disabled !default;

/// @type Value
/// @access public
/// @group tabs
$tab-underline-disabled: 2px solid $border-disabled !default;
