@use '@carbon/styles/scss/reset';
@use '@carbon/styles/scss/components';

@use "@angular/material" as mat;

@include mat.elevation-classes();
@include mat.app-background();

@import "styles/material-modifications.css";
@import "styles/ibm-modifications.css";
@import "styles/ng-select-modifications.css";
@import "styles/owl-modifications.css";
@import "styles/cognitive-search.css";
@import "styles/quill-editor.css";
@import "styles/themes/light-theme.css";
@import "styles/themes/dark-theme.css";
@import "@ng-select/ng-select/themes/default.theme.css";

html,
body {
  height: 100%;
}

body {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100, "YTLC" 500;

  color: var(--color-text);

  background-color: var(--color-background);
}

button {
  color: var(--color-text);

  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100, "YTLC" 500;
}

.check_active {
  padding-left: 10px;
  filter: invert(62%) sepia(81%) saturate(1867%) hue-rotate(122deg) brightness(92%) contrast(81%);
  width: 28px;
}

.check_inactive {
  padding-left: 7px;
  filter: invert(12%) sepia(88%) saturate(6164%) hue-rotate(9deg) brightness(103%) contrast(124%);
  width: 24px;
}

.bx--select-input {
  border-bottom: 0 !important;
  outline: none !important;
}

.spinner-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  height: 100vh;
  width: 100vw;
}

.spinner-image {
  width: 90px;
  height: 90px;
}

.spinner-image img {
  -webkit-animation: rotateAnimation 2s linear infinite;
  animation: rotateAnimation 2s linear infinite;
  width: 100%;
}

/* Safari */
@-webkit-keyframes rotateAnimation {
  from {
    transform: rotateY(45deg);
  }
  to {
    transform: rotateY(225deg);
  }
}

@keyframes rotateAnimation {
  from {
    transform: rotateY(45deg);
  }
  to {
    transform: rotateY(225deg);
  }
}

$custom-primary-palette: (
  50: #e1f5fe,
  100: #c5eafc,
  200: #9ad8f5,
  300: #6dccf8,
  400: #3ebefa,
  500: #2ab1f4,
  600: #2ab1f4,
  700: #2ab1f4,
  800: #2ab1f4,
  900: #2ab1f4,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
  ),
);

$custom-primary: mat.m2-define-palette($custom-primary-palette);
$theme-accent: mat.m2-define-palette(mat.$m2-indigo-palette);

$custom-theme: mat.m2-define-light-theme(
    (
      color: (
        theme-type: light,
        primary: $custom-primary,
        accent: $custom-primary,
      ),
    )
);

@include mat.all-component-themes($custom-theme);
