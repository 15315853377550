//
// Copyright IBM Corp. 2021
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@use '../theme';

/// Adds box shadow
/// @access public
/// @example @include box-shadow;
/// @group utilities
@mixin box-shadow {
  box-shadow: 0 2px 6px theme.$shadow;
}

@mixin box-shadow-top {
  box-shadow: 0 -2px 6px theme.$shadow;
}
