//
// Copyright IBM Corp. 2021
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@use '../theme';

/// Adds outline styles depending on specific type
/// @access public
/// @param {String} $type ['border'] - Type of outline from: `border`, `blurred`, `outline`, `invalid`, `reset`
/// @example @include focus-outline('outline');
/// @group utilities
@mixin focus-outline($type: 'border') {
  @if ($type == 'border') {
    outline: 1px solid theme.$focus;

    @media screen and (prefers-contrast) {
      outline-style: dotted;
    }
  }

  @if ($type == 'blurred') {
    box-shadow: 0 0 0 3px theme.$focus;
    outline: 1px solid transparent;
  }

  @if ($type == 'outline') {
    outline: 2px solid theme.$focus;
    outline-offset: -2px;

    @media screen and (prefers-contrast) {
      outline-style: dotted;
    }
  }

  @if ($type == 'outline-compat') {
    box-sizing: border-box;
    border: 2px solid theme.$focus;

    @media screen and (prefers-contrast) {
      border-style: dotted;
    }
  }

  @if ($type == 'invalid') {
    outline: 2px solid theme.$support-error;
    outline-offset: -2px;

    @media screen and (prefers-contrast) {
      outline-style: dotted;
    }
  }

  @if ($type == 'reset') {
    outline: 2px solid transparent;
    outline-offset: -2px;
  }
}
