
.ibmIcon {
  fill: #00a8e1;
  cursor: pointer;
}

.ibmIcon-danger {
  fill: #00a8e1;
  cursor: pointer;
}

.ibmIcon-active {
  fill: #00a8e1;
  cursor: pointer;
}

.ibmIcon-error {
  fill: #dc3545;
  cursor: pointer;
}

.custom_textarea {
  resize: vertical !important;
  padding: 0;
}

.searchBox__suggestions-list {
  padding-top: -10px;
  box-sizing: border-box;
  padding-left: 0px;
  margin-right: 1px;
}

.searchBox__suggestions-list {
  padding-top: -10px;
  box-sizing: border-box;
  padding-left: 0px;
  margin-right: 1px;
}

.searchBox__suggestion--focused {
  background: #66afe9 !important;
  color: white !important;
}

.searchBox__suggestions-list li {
  list-style: none;
  padding-left: 1em;
  padding-top: .5em;
  padding-bottom: .5em;
  background: white;
  box-shadow: inset 0 0 0 1px #66afe9 !important;
  border-radius: 3px;
}

.pager__pageLink {
  cursor: pointer;
}

button.cds--btn {
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  position: relative;
}

button.cds--btn > svg {
  position: absolute;
  right: 1rem;
}

button.cds--btn--primary {
  background-color: var(--color-primary);
}

button.cds--btn--primary:hover {
  background-color: var(--color-primary-hover);
}

.cds--tabs.cds--tabs--contained .cds--tabs__nav-item.cds--tabs__nav-item--selected {
  box-shadow: inset 0 2px 0 0 var(--cds-border-interactive, var(--color-primary)) !important;
}

.cds--tabs.cds--tabs--contained .cds--tabs__nav-item {
  outline: none !important;
}

.cds--tabs:not(.cds--tabs--contained) .cds--tabs__nav-item--selected {
  border-block-end: 2px solid var(--cds-border-interactive, var(--color-primary)) !important; 
}

button.cds--modal-close.cds--btn.cds--btn--primary {
  background-color: transparent;
  transition: .11s cubic-bezier(.2,0,.38,.9);
}

button.cds--modal-close.cds--btn.cds--btn--primary:hover {
  background-color: #e5e5e5;
}

section.cds--modal {
  z-index: 9999;
}