.searchBox__suggestions-list {
  padding-top: -10px;
  box-sizing: border-box;
  padding-left: 0px;
  margin-right: 1px;
}

.searchBox__suggestions-list {
  padding-top: -10px;
  box-sizing: border-box;
  padding-left: 0px;
  margin-right: 1px;
}

.searchBox__suggestion--focused {
  background: #66afe9 !important;
  color: white !important;
}

.searchBox__suggestions-list li {
  list-style: none;
  padding-left: 1em;
  padding-top: .5em;
  padding-bottom: .5em;
  background: white;
  box-shadow: inset 0 0 0 1px #66afe9 !important;
  border-radius: 3px;
}

.pager__pageLink {
  cursor: pointer;
}

.collapse:not(.show) {
  display: block;
}
