// Code generated by @carbon/colors. DO NOT EDIT.
//
// Copyright IBM Corp. 2018, 2023
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

$black: #000000 !default;
$white: #ffffff !default;

$black-100: #000000 !default;
$blue-10: #edf5ff !default;
$blue-20: #d0e2ff !default;
$blue-30: #a6c8ff !default;
$blue-40: #78a9ff !default;
$blue-50: #4589ff !default;
$blue-60: #0f62fe !default;
$blue-70: #0043ce !default;
$blue-80: #002d9c !default;
$blue-90: #001d6c !default;
$blue-100: #001141 !default;
$cool-gray-10: #f2f4f8 !default;
$cool-gray-20: #dde1e6 !default;
$cool-gray-30: #c1c7cd !default;
$cool-gray-40: #a2a9b0 !default;
$cool-gray-50: #878d96 !default;
$cool-gray-60: #697077 !default;
$cool-gray-70: #4d5358 !default;
$cool-gray-80: #343a3f !default;
$cool-gray-90: #21272a !default;
$cool-gray-100: #121619 !default;
$cyan-10: #e5f6ff !default;
$cyan-20: #bae6ff !default;
$cyan-30: #82cfff !default;
$cyan-40: #33b1ff !default;
$cyan-50: #1192e8 !default;
$cyan-60: #0072c3 !default;
$cyan-70: #00539a !default;
$cyan-80: #003a6d !default;
$cyan-90: #012749 !default;
$cyan-100: #061727 !default;
$gray-10: #f4f4f4 !default;
$gray-20: #e0e0e0 !default;
$gray-30: #c6c6c6 !default;
$gray-40: #a8a8a8 !default;
$gray-50: #8d8d8d !default;
$gray-60: #6f6f6f !default;
$gray-70: #525252 !default;
$gray-80: #393939 !default;
$gray-90: #262626 !default;
$gray-100: #161616 !default;
$green-10: #defbe6 !default;
$green-20: #a7f0ba !default;
$green-30: #6fdc8c !default;
$green-40: #42be65 !default;
$green-50: #24a148 !default;
$green-60: #198038 !default;
$green-70: #0e6027 !default;
$green-80: #044317 !default;
$green-90: #022d0d !default;
$green-100: #071908 !default;
$magenta-10: #fff0f7 !default;
$magenta-20: #ffd6e8 !default;
$magenta-30: #ffafd2 !default;
$magenta-40: #ff7eb6 !default;
$magenta-50: #ee5396 !default;
$magenta-60: #d02670 !default;
$magenta-70: #9f1853 !default;
$magenta-80: #740937 !default;
$magenta-90: #510224 !default;
$magenta-100: #2a0a18 !default;
$orange-10: #fff2e8 !default;
$orange-20: #ffd9be !default;
$orange-30: #ffb784 !default;
$orange-40: #ff832b !default;
$orange-50: #eb6200 !default;
$orange-60: #ba4e00 !default;
$orange-70: #8a3800 !default;
$orange-80: #5e2900 !default;
$orange-90: #3e1a00 !default;
$orange-100: #231000 !default;
$purple-10: #f6f2ff !default;
$purple-20: #e8daff !default;
$purple-30: #d4bbff !default;
$purple-40: #be95ff !default;
$purple-50: #a56eff !default;
$purple-60: #8a3ffc !default;
$purple-70: #6929c4 !default;
$purple-80: #491d8b !default;
$purple-90: #31135e !default;
$purple-100: #1c0f30 !default;
$red-10: #fff1f1 !default;
$red-20: #ffd7d9 !default;
$red-30: #ffb3b8 !default;
$red-40: #ff8389 !default;
$red-50: #fa4d56 !default;
$red-60: #da1e28 !default;
$red-70: #a2191f !default;
$red-80: #750e13 !default;
$red-90: #520408 !default;
$red-100: #2d0709 !default;
$teal-10: #d9fbfb !default;
$teal-20: #9ef0f0 !default;
$teal-30: #3ddbd9 !default;
$teal-40: #08bdba !default;
$teal-50: #009d9a !default;
$teal-60: #007d79 !default;
$teal-70: #005d5d !default;
$teal-80: #004144 !default;
$teal-90: #022b30 !default;
$teal-100: #081a1c !default;
$warm-gray-10: #f7f3f2 !default;
$warm-gray-20: #e5e0df !default;
$warm-gray-30: #cac5c4 !default;
$warm-gray-40: #ada8a8 !default;
$warm-gray-50: #8f8b8b !default;
$warm-gray-60: #726e6e !default;
$warm-gray-70: #565151 !default;
$warm-gray-80: #3c3838 !default;
$warm-gray-90: #272525 !default;
$warm-gray-100: #171414 !default;
$white-0: #ffffff !default;
$yellow-10: #fcf4d6 !default;
$yellow-20: #fddc69 !default;
$yellow-30: #f1c21b !default;
$yellow-40: #d2a106 !default;
$yellow-50: #b28600 !default;
$yellow-60: #8e6a00 !default;
$yellow-70: #684e00 !default;
$yellow-80: #483700 !default;
$yellow-90: #302400 !default;
$yellow-100: #1c1500 !default;

$white-hover: #e8e8e8 !default;
$black-hover: #212121 !default;
$blue-10-hover: #dbebff !default;
$blue-20-hover: #b8d3ff !default;
$blue-30-hover: #8ab6ff !default;
$blue-40-hover: #5c97ff !default;
$blue-50-hover: #1f70ff !default;
$blue-60-hover: #0050e6 !default;
$blue-70-hover: #0053ff !default;
$blue-80-hover: #0039c7 !default;
$blue-90-hover: #00258a !default;
$blue-100-hover: #001f75 !default;
$cool-gray-10-hover: #e4e9f1 !default;
$cool-gray-20-hover: #cdd3da !default;
$cool-gray-30-hover: #adb5bd !default;
$cool-gray-40-hover: #9199a1 !default;
$cool-gray-50-hover: #757b85 !default;
$cool-gray-60-hover: #585e64 !default;
$cool-gray-70-hover: #5d646a !default;
$cool-gray-80-hover: #434a51 !default;
$cool-gray-90-hover: #2b3236 !default;
$cool-gray-100-hover: #222a2f !default;
$cyan-10-hover: #cceeff !default;
$cyan-20-hover: #99daff !default;
$cyan-30-hover: #57beff !default;
$cyan-40-hover: #059fff !default;
$cyan-50-hover: #0f7ec8 !default;
$cyan-60-hover: #005fa3 !default;
$cyan-70-hover: #0066bd !default;
$cyan-80-hover: #00498a !default;
$cyan-90-hover: #013360 !default;
$cyan-100-hover: #0b2947 !default;
$gray-10-hover: #e8e8e8 !default;
$gray-20-hover: #d1d1d1 !default;
$gray-30-hover: #b5b5b5 !default;
$gray-40-hover: #999999 !default;
$gray-50-hover: #7a7a7a !default;
$gray-60-hover: #5e5e5e !default;
$gray-70-hover: #636363 !default;
$gray-80-hover: #474747 !default;
$gray-90-hover: #333333 !default;
$gray-100-hover: #292929 !default;
$green-10-hover: #b6f6c8 !default;
$green-20-hover: #74e792 !default;
$green-30-hover: #36ce5e !default;
$green-40-hover: #3bab5a !default;
$green-50-hover: #208e3f !default;
$green-60-hover: #166f31 !default;
$green-70-hover: #11742f !default;
$green-80-hover: #05521c !default;
$green-90-hover: #033b11 !default;
$green-100-hover: #0d300f !default;
$magenta-10-hover: #ffe0ef !default;
$magenta-20-hover: #ffbdda !default;
$magenta-30-hover: #ff94c3 !default;
$magenta-40-hover: #ff57a0 !default;
$magenta-50-hover: #e3176f !default;
$magenta-60-hover: #b0215f !default;
$magenta-70-hover: #bf1d63 !default;
$magenta-80-hover: #8e0b43 !default;
$magenta-90-hover: #68032e !default;
$magenta-100-hover: #53142f !default;
$orange-10-hover: #ffe2cc !default;
$orange-20-hover: #ffc69e !default;
$orange-30-hover: #ff9d57 !default;
$orange-40-hover: #fa6800 !default;
$orange-50-hover: #cc5500 !default;
$orange-60-hover: #9e4200 !default;
$orange-70-hover: #a84400 !default;
$orange-80-hover: #753300 !default;
$orange-90-hover: #522200 !default;
$orange-100-hover: #421e00 !default;
$purple-10-hover: #ede5ff !default;
$purple-20-hover: #dcc7ff !default;
$purple-30-hover: #c5a3ff !default;
$purple-40-hover: #ae7aff !default;
$purple-50-hover: #9352ff !default;
$purple-60-hover: #7822fb !default;
$purple-70-hover: #7c3dd6 !default;
$purple-80-hover: #5b24ad !default;
$purple-90-hover: #40197b !default;
$purple-100-hover: #341c59 !default;
$red-10-hover: #ffe0e0 !default;
$red-20-hover: #ffc2c5 !default;
$red-30-hover: #ff99a0 !default;
$red-40-hover: #ff6168 !default;
$red-50-hover: #ee0713 !default;
$red-60-hover: #b81922 !default;
$red-70-hover: #c21e25 !default;
$red-80-hover: #921118 !default;
$red-90-hover: #66050a !default;
$red-100-hover: #540d11 !default;
$teal-10-hover: #acf6f6 !default;
$teal-20-hover: #57e5e5 !default;
$teal-30-hover: #25cac8 !default;
$teal-40-hover: #07aba9 !default;
$teal-50-hover: #008a87 !default;
$teal-60-hover: #006b68 !default;
$teal-70-hover: #007070 !default;
$teal-80-hover: #005357 !default;
$teal-90-hover: #033940 !default;
$teal-100-hover: #0f3034 !default;
$warm-gray-10-hover: #f0e8e6 !default;
$warm-gray-20-hover: #d8d0cf !default;
$warm-gray-30-hover: #b9b3b1 !default;
$warm-gray-40-hover: #9c9696 !default;
$warm-gray-50-hover: #7f7b7b !default;
$warm-gray-60-hover: #605d5d !default;
$warm-gray-70-hover: #696363 !default;
$warm-gray-80-hover: #4c4848 !default;
$warm-gray-90-hover: #343232 !default;
$warm-gray-100-hover: #2c2626 !default;
$yellow-10-hover: #f8e6a0 !default;
$yellow-20-hover: #fccd27 !default;
$yellow-30-hover: #ddb00e !default;
$yellow-40-hover: #bc9005 !default;
$yellow-50-hover: #9e7700 !default;
$yellow-60-hover: #755800 !default;
$yellow-70-hover: #806000 !default;
$yellow-80-hover: #5c4600 !default;
$yellow-90-hover: #3d2e00 !default;
$yellow-100-hover: #332600 !default;

/// Colors from the IBM Design Language
/// @access public
/// @group @carbon/colors
$colors: (
  black: (
    100: #000000,
  ),
  blue: (
    10: #edf5ff,
    20: #d0e2ff,
    30: #a6c8ff,
    40: #78a9ff,
    50: #4589ff,
    60: #0f62fe,
    70: #0043ce,
    80: #002d9c,
    90: #001d6c,
    100: #001141,
  ),
  cool-gray: (
    10: #f2f4f8,
    20: #dde1e6,
    30: #c1c7cd,
    40: #a2a9b0,
    50: #878d96,
    60: #697077,
    70: #4d5358,
    80: #343a3f,
    90: #21272a,
    100: #121619,
  ),
  cyan: (
    10: #e5f6ff,
    20: #bae6ff,
    30: #82cfff,
    40: #33b1ff,
    50: #1192e8,
    60: #0072c3,
    70: #00539a,
    80: #003a6d,
    90: #012749,
    100: #061727,
  ),
  gray: (
    10: #f4f4f4,
    20: #e0e0e0,
    30: #c6c6c6,
    40: #a8a8a8,
    50: #8d8d8d,
    60: #6f6f6f,
    70: #525252,
    80: #393939,
    90: #262626,
    100: #161616,
  ),
  green: (
    10: #defbe6,
    20: #a7f0ba,
    30: #6fdc8c,
    40: #42be65,
    50: #24a148,
    60: #198038,
    70: #0e6027,
    80: #044317,
    90: #022d0d,
    100: #071908,
  ),
  magenta: (
    10: #fff0f7,
    20: #ffd6e8,
    30: #ffafd2,
    40: #ff7eb6,
    50: #ee5396,
    60: #d02670,
    70: #9f1853,
    80: #740937,
    90: #510224,
    100: #2a0a18,
  ),
  orange: (
    10: #fff2e8,
    20: #ffd9be,
    30: #ffb784,
    40: #ff832b,
    50: #eb6200,
    60: #ba4e00,
    70: #8a3800,
    80: #5e2900,
    90: #3e1a00,
    100: #231000,
  ),
  purple: (
    10: #f6f2ff,
    20: #e8daff,
    30: #d4bbff,
    40: #be95ff,
    50: #a56eff,
    60: #8a3ffc,
    70: #6929c4,
    80: #491d8b,
    90: #31135e,
    100: #1c0f30,
  ),
  red: (
    10: #fff1f1,
    20: #ffd7d9,
    30: #ffb3b8,
    40: #ff8389,
    50: #fa4d56,
    60: #da1e28,
    70: #a2191f,
    80: #750e13,
    90: #520408,
    100: #2d0709,
  ),
  teal: (
    10: #d9fbfb,
    20: #9ef0f0,
    30: #3ddbd9,
    40: #08bdba,
    50: #009d9a,
    60: #007d79,
    70: #005d5d,
    80: #004144,
    90: #022b30,
    100: #081a1c,
  ),
  warm-gray: (
    10: #f7f3f2,
    20: #e5e0df,
    30: #cac5c4,
    40: #ada8a8,
    50: #8f8b8b,
    60: #726e6e,
    70: #565151,
    80: #3c3838,
    90: #272525,
    100: #171414,
  ),
  white: (
    0: #ffffff,
  ),
  yellow: (
    10: #fcf4d6,
    20: #fddc69,
    30: #f1c21b,
    40: #d2a106,
    50: #b28600,
    60: #8e6a00,
    70: #684e00,
    80: #483700,
    90: #302400,
    100: #1c1500,
  ),
) !default;
