//
// Copyright IBM Corp. 2021
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@use '../config' as *;

/// Include styles for screen reader/assistive text
/// @access public
/// @group utilities
@mixin visually-hidden {
  position: absolute;
  overflow: hidden;
  padding: 0;
  border: 0;
  margin: -1px;
  block-size: 1px;
  clip: rect(0, 0, 0, 0);
  inline-size: 1px;
  visibility: inherit;
  white-space: nowrap;
}

.#{$prefix}--assistive-text,
.#{$prefix}--visually-hidden {
  position: absolute;
  overflow: hidden;
  padding: 0;
  border: 0;
  margin: -1px;
  block-size: 1px;
  clip: rect(0, 0, 0, 0);
  inline-size: 1px;
  visibility: inherit;
  white-space: nowrap;
}
