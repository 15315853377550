//
// Copyright IBM Corp. 2016, 2023
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@use '../../config' as *;
@use '../../utilities/skeleton' as *;
@use '../../utilities/convert';
@use '../../spacing' as *;

/// Skeleton styles
/// @access public
/// @group skeleton
@mixin skeleton-styles {
  //skeleton icon
  .#{$prefix}--icon--skeleton {
    @include skeleton;

    display: inline-block;
    block-size: convert.to-rem(16px);
    inline-size: convert.to-rem(16px);
  }

  //skeleton placeholder
  .#{$prefix}--skeleton__placeholder {
    @include skeleton;

    block-size: convert.to-rem(100px);

    inline-size: convert.to-rem(100px);
  }

  //skeleton text
  .#{$prefix}--skeleton__text {
    @include skeleton;

    block-size: 1rem;

    inline-size: 100%;

    margin-block-end: $spacing-03;
  }

  .#{$prefix}--skeleton__heading {
    block-size: 1.5rem;
  }
}
