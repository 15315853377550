//
// Copyright IBM Corp. 2016, 2023
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@use '../../../config' as *;
@use '../../../spacing' as *;
@use '../../../theme' as *;
@use '../../../type' as *;
@use '../../../utilities/component-reset';
@use '../../../utilities/convert';

/// UI shell side nav
/// @access private
/// @group ui-shell
@mixin switcher {
  //----------------------------------------------------------------------------
  // Header Switcher
  //----------------------------------------------------------------------------
  .#{$prefix}--switcher {
    @include component-reset.reset;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $text-secondary;
  }

  .#{$prefix}--switcher__item {
    block-size: $spacing-07;
    inline-size: 100%;
  }

  .#{$prefix}--switcher__item:nth-child(1) {
    margin-block-start: $spacing-05;
  }

  .#{$prefix}--switcher__item--divider {
    display: block;
    border: none;
    margin: $spacing-03 $spacing-05;
    background: $border-subtle;
    block-size: 1px;
    inline-size: convert.to-rem(224px);
  }

  .#{$prefix}--switcher__item-link {
    @include type-style('heading-compact-01');

    display: block;
    padding: convert.to-rem(6px) $spacing-05;
    block-size: $spacing-07;
    color: $text-secondary;
    text-decoration: none;

    &:hover:not(.#{$prefix}--switcher__item-link--selected) {
      background: $layer-hover;
      color: $text-primary;
      cursor: pointer;
    }

    &:focus {
      outline: 2px solid $focus;
      outline-offset: -2px;
    }

    &:active {
      background: $layer-active;
      color: $text-primary;
    }
  }

  .#{$prefix}--switcher__item-link--selected {
    //TO-DO: should we add a 3px left border-interactive here too? Doesn't currently have it, but specs have it
    background: $layer-selected;
    color: $text-primary;
  }
}
