//
// Copyright IBM Corp. 2021
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@forward './layer/layer-sets';
@use './config' as *;
@use './layer/layer-tokens';

:root {
  @include layer-tokens.emit-layer-tokens(1);
}

.#{$prefix}--layer-one {
  @include layer-tokens.emit-layer-tokens(1);
}

.#{$prefix}--layer-two {
  @include layer-tokens.emit-layer-tokens(2);
}

.#{$prefix}--layer-three {
  @include layer-tokens.emit-layer-tokens(3);
}
