//
// Copyright IBM Corp. 2021
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

/// High Contrast Mode mixin for Windows and macOS
/// @access public
/// @example @include high-contrast-mode;
/// @group utilities
/// Set HCM styles at the end of each file to ensure they are not overwritten
@mixin high-contrast-mode($type: '') {
  @media screen and (-ms-high-contrast: active), (forced-colors: active) {
    @if ($type == 'icon-fill') {
      fill: ButtonText;
    }

    @if ($type == 'focus') {
      color: Highlight;
      outline: 1px solid Highlight;
    }

    @if ($type == 'outline') {
      outline: 1px solid transparent;
    }

    @if ($type == 'disabled') {
      color: GrayText;
      fill: GrayText;
    }

    @content;
  }
}
