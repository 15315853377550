//
// Copyright IBM Corp. 2021
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@use '../theme' as *;

/// Adds placeholder text color
/// @access public
/// @example @include placeholder-colors;
/// @group utilities
@mixin placeholder-colors {
  color: $text-placeholder;
  opacity: 1;
}
