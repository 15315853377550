//
// Copyright IBM Corp. 2016, 2023
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@use '../../theme' as *;
@use '../../type' as *;
@use '../../utilities/convert';

/// Code snippet base styles
/// @access private
/// @group code-snippet
@mixin bx--snippet {
  @include type-style('code-01');

  position: relative;
  background-color: $layer;
  inline-size: 100%;
  max-inline-size: convert.to-rem(768px);
}
