:root {
  /* Begin main colors */

  --color-primary: #2ab1f4;
  --color-primary-rgb: 42, 177, 244;
  --color-primary-hover: #1d9fe8;
  --color-on-primary: #ffffff;

  --color-secondary: #2ecc71;
  --color-secondary-rgb: 46, 204, 113;
  --color-secondary-hover: #27ae60;
  --color-on-secondary: #ffffff;

  --color-error: #e74c3c;
  --color-error-rgb: 231, 76, 60;
  --color-error-hover: #c0392b;
  --color-on-error: #ffffff;

  --color-warning: #F5963A;
  --color-warning-rgb: 245, 150, 58;
  --color-warning-hover: #F07C00;
  --color-on-warning: #ffffff;

  --color-disabled: #6c6c6c;
  --color-disabled-rgb: 108, 108, 108;
  --color-on-disabled: #ffffff;

  /* End main colors */

  /* Begin Background colors */

  --color-background: #f2f2f2;

  --color-surface: #ffffff;

  --color-sidebar: #0A2754;
  --color-on-sidebar: #ffffff;
  --color-on-sidebar-rgb: 255, 255, 255;
  --color-depth-opacity-on-sidebar: .15;

  --color-main-header: #0A2754;

  --color-gray: #bbb;
  --color-gray-hover: #a3a3a3;
  --color-text-gray: #434343;

  /* End background colors */

  /* Begin text colors */

  --color-text: #000000;
  --color-text-rgb: 0, 0, 0;

  --color-text-secondary: rgba(0, 0, 0, 0.7);
  --color-text-hint: #6c6c6c;

  /* End text colors */
}
